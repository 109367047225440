import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["entity", "submit"]
  static values = {
    signs: Number
  }

  connect() {
    this.toggleDisable()
  }

  sign() {
    this.toggleDisable()
  }

  toggleDisable() {
    if (this.canSubmit) {
      this.submitTarget.classList.remove('btn-md-disabled');
      this.submitTarget.classList.add('btn-md');
    } else {
      this.submitTarget.classList.remove('btn-md');
      this.submitTarget.classList.add('btn-md-disabled');
    }
  }

  submit(e) {
    if (!this.canSubmit) {
      e.preventDefault();
    }
  }

  get canSubmit() {
    return this.entityTargets.filter(entity => entity.checked).length == this.signsValue;
  }
}
