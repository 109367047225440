import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "first", "second", "third"]

  connect() {
    this.setIndividualFields();
  }

  first_changed() {
    if (this.firstTarget.value) {
      this.secondTarget.focus();
    }
    this.setInputField();
  }

  second_changed() {
    if (this.secondTarget.value) {
      this.thirdTarget.focus();
    }
    this.setInputField();
  }

  third_changed() {
    this.setInputField();
  }

  setIndividualFields() {
    const [first, second, third] = String(this.inputTarget.value).split('');
    if (first) {
      this.firstTarget.value = first;
    }
    if (second) {
      this.secondTarget.value = second;
    }
    if (third) {
      this.thirdTarget.value = third;
    }
  }

  setInputField() {
    this.inputTarget.value = `${this.firstTarget.value}${this.secondTarget.value}${this.thirdTarget.value}`.trim();
  }
}
