// Entry point for the build script in your package.json

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as ActiveStorage from "@rails/activestorage";
import Alpine from "alpinejs";

import { Turbo } from "@hotwired/turbo-rails"

document.addEventListener('turbo:submit-start', function () {
  Turbo.navigator.delegate.adapter.showProgressBar();
});

document.addEventListener('turbo:submit-end', function () {
  Turbo.navigator.delegate.adapter.hideFormProgressBar();
});

import "./controllers";

window["Alpine"] = Alpine;

ActiveStorage.start();
Alpine.start();
