import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["entity", "button"]

  connect() {
    this.toggleSubmit();
  }

  selectAll(e) {
    e.preventDefault();

    this.entityTargets.forEach((element, _index) => {
      element.checked = true;
    })

    this.toggleSubmit();
  }

  selectNone(e) {
    e.preventDefault();

    this.entityTargets.forEach((element, _index) => {
      element.checked = false;
    })

    this.toggleSubmit();
  }

  toggleSubmit() {
    let shouldDisable = true;

    this.entityTargets.forEach((element, _index) => {
      if (element.checked === true){
        shouldDisable = false;
      }
    })

    if (shouldDisable) {
      this.buttonTarget.classList.remove('btn-md');
      this.buttonTarget.classList.add('hidden');
    } else {
      this.buttonTarget.classList.remove('hidden');
      this.buttonTarget.classList.add('btn-md');
    }
  }
}
