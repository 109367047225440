import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "filter", "input", "noResults", "template"];

  static values = {
    otherShareholdings: Array,
  };

  readonly containerTarget: HTMLElement;
  readonly filterTarget: HTMLInputElement;
  readonly inputTargets: HTMLInputElement[];
  readonly noResultsTarget: HTMLElement;
  readonly otherShareholdingsValue: String[];
  readonly templateTarget: HTMLElement;

  connect(): void {
    this.inputTargets.map(this.addToggleEvent.bind(this));
    this.filterTarget.addEventListener("input", this.handleFilter.bind(this));

    this.addInputPills();
    this.addOtherShareholdingPills();
  }

  disconnect(): void {
    this.inputTargets.map(this.removeToggleEvent.bind(this));
    this.filterTarget.removeEventListener(
      "input",
      this.handleFilter.bind(this)
    );
  }

  addToggleEvent(element: HTMLInputElement): void {
    element.addEventListener("change", this.togglePill.bind(this));
  }

  removeToggleEvent(element: HTMLInputElement): void {
    element.removeEventListener("change", this.togglePill.bind(this));
  }

  addInputPills() {
    this.inputTargets
      .filter((input) => input.checked)
      .forEach(this.addInputPill.bind(this));
  }

  addInputPill(input: HTMLInputElement) {
    const className = "indigo";
    const element = this.pill(className, input.dataset.companiesValue);

    element.addEventListener("click", () => {
      input.checked = false;
      element.remove();
    });

    this.appendElement(element);
  }

  addOtherShareholdingPills() {
    this.otherShareholdingsValue.forEach(this.addOtherShareholding.bind(this));
  }

  addFilterValue(event: MouseEvent) {
    event.preventDefault();
    this.addOtherShareholding(this.filterTarget.value);
  }

  addOtherShareholding(title: string) {
    const className = "gray";
    const filter = this.filterTarget.value;
    const input = this.otherSharholdingInput(title || filter);
    const pill = this.pill(className, title);

    pill.append(input);
    pill.addEventListener("click", () => pill.remove());

    this.appendElement(pill);
  }

  otherSharholdingInput(value: string): HTMLInputElement {
    const element = document.createElement("input");

    element.setAttribute("name", "entity[other_shareholdings][]");
    element.setAttribute("value", value);
    element.classList.add("hidden");

    return element;
  }

  appendElement(element: HTMLElement) {
    this.containerTarget.append(element);
  }

  removePill(text: string) {
    const elements = this.containerTarget.getElementsByClassName("pill");

    for (let element of elements) {
      if (element.textContent.includes(text)) {
        element.remove();
        break;
      }
    }
  }

  pill(className: string, title: string): HTMLElement {
    const element = this.templateTarget.cloneNode(true) as HTMLElement;
    element.classList.add(`pill-${className}`);
    element.classList.remove("hidden");
    element.removeAttribute("data-companies-target");

    const name = element.querySelector("[data-companies-name]");
    name.textContent = title;

    const button = element.querySelector("[data-companies-button]");
    button.classList.add(`btn-x-${className}`);

    return element;
  }

  togglePill(event: MouseEvent) {
    const input = event.currentTarget as HTMLInputElement;

    if (input.checked) {
      this.addInputPill(input);
    } else {
      this.removePill(input.dataset.companiesValue);
    }
  }

  handleFilter(event: MouseEvent) {
    const { value } = event.currentTarget as HTMLInputElement;
    const valid = value.length >= 3;
    const regex = valid ? new RegExp(value, "i") : undefined;

    let count = 0;

    this.inputTargets.forEach((target) => {
      const { companiesValue } = target.dataset;
      const { classList } = target.closest('li');

      if (!regex || regex.test(companiesValue)) {
        classList.remove("hidden");
        count += 1;
      } else {
        classList.add("hidden");
      }
    });

    if (valid && count === 0) {
      this.noResultsTarget.classList.remove("hidden");
      this.noResultsTarget.querySelector(
        "[data-companies-name]"
      ).textContent = `"${value}"`;
    } else {
      this.noResultsTarget.classList.add("hidden");
    }
  }
}
