import Rails from "@rails/ujs";
import { Application } from "@hotwired/stimulus";
import Reveal from "stimulus-reveal-controller";

const application = Application.start();

application.register("reveal", Reveal);

// Configure Stimulus development experience
application.debug = false;
Rails.start();

// Expose globally
window["Stimulus"] = application;

export { application };
