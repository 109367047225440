import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["own", "entity", "joint"];
  static values = {
    under: String
  }

  connect() {
    if (this.underValue === 'entity') {
      this.showEntity();
    } else if (this.underValue === 'my_name_and_other_individuals') {
      this.showJoint();
    } else {
      this.showOwn();
    }
  }

  entity_click() {
    this.showEntity();
  }

  own_click() {
    this.showOwn();
  }

  joint_click() {
    this.showJoint();
  }

  showEntity() {
    this.entityTarget.classList.remove('hidden');
    this.ownTarget.classList.add('hidden');
    this.jointTarget.classList.add('hidden');
  }

  showOwn() {
    this.ownTarget.classList.remove('hidden');
    this.entityTarget.classList.add('hidden');
    this.jointTarget.classList.add('hidden');
  }

  showJoint() {
    this.ownTarget.classList.add('hidden');
    this.entityTarget.classList.add('hidden');
    this.jointTarget.classList.remove('hidden');
  }
}
