import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const editEntityId = urlParams.get('edit_entity_id')
    if (editEntityId) {
      var editEntityLink = document.getElementById(`edit_entity_${editEntityId}`);
      if (editEntityLink) {
        editEntityLink.click();

        setTimeout(function () {
          document.getElementById(`entity_${editEntityId}`).scrollIntoView({
            behavior: 'smooth'
          });
        }, 150)
      }
    }
  }

}
